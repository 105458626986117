<template>
  <div class="sign_wrp">
    <div class="d-block w-100">
      <div class="mobile_header">
        <span class="pl-0">Sign in</span>
      </div>

      <div class="sign-in-desktop_wrapper">
        <form @submit.prevent="onSubmit">
          <div class="flex-folumn">
            <div class="form-group">
              <label for="login-email">Email Address </label>
              <input
                id="login-email"
                v-model="email"
                autocomplete="username"
                name="email"
                placeholder="Email Address"
                type="email"
                @input="loginError = ''"
                @blur="$v.email.$touch()"
              />
              <div v-if="$v.email.$error || loginError" class="form-dark-bg-error form-dark-bg-error--login-form">
                <small v-if="!$v.email.required && $v.email.$dirty" class="form-dark-bg-error__msg">
                  Email field is required.
                </small>
                <small v-else-if="!$v.email.email" class="form-dark-bg-error__msg">
                  Please provide a valid email.
                </small>
                <small v-else-if="loginError" class="form-dark-bg-error__msg">{{ loginError }}</small>
              </div>
            </div>

            <div class="form-group">
              <label for="login-password">Password</label>
              <div class="field-wrap">
                <input
                  id="login-password"
                  v-model="password"
                  autocomplete="current-password"
                  placeholder="Password"
                  type="password"
                  @blur="$v.password.$touch()"
                />
                <div class="forgot-pasword">
                  <RouterLink :to="{ name: 'forgot-password' }">Forgot?</RouterLink>
                </div>
              </div>
              <div v-if="$v.password.$error" class="form-dark-bg-error form-dark-bg-error--login-form">
                <small v-if="!$v.password.required && $v.password.$dirty" class="form-dark-bg-error__msg">
                  Password field is required.
                </small>
              </div>
            </div>
          </div>

          <div class="keep-login-container">
            <AuthFormCheckbox id="keep-login" v-model="keepMeLoggedIn" class="keep-login-element">
              <template #label>Keep me logged in</template>
            </AuthFormCheckbox>
          </div>

          <div class="btn">
            <button type="submit">Sign in</button>
          </div>

          <div class="sign-in-desktop_wrapper__footer">
            <RouterLink :to="{ name: 'forgot-password' }">Forgotten password</RouterLink>
            <RouterLink :to="{ name: 'activate-account' }">Activate your account</RouterLink>
          </div>
        </form>
      </div>
    </div>

    <div class="not-member">
      <div class="flex-column">
        <a :href="marketingSiteBaseUrl">
          <div class="flex-column">
            <h5>Not a member?</h5>
            <p>The Cultivist is a members-only platform, join us now to unlock the art.</p>
          </div>
          <TheArrowHorizIcon flip />
        </a>
      </div>
    </div>

    <AppLegalInfoLink class="common-legal-link" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { email, minLength, required } from 'vuelidate/lib/validators';
import AppConfig from '@/config';

import AppLegalInfoLink from '@/components/partials/AppLegalInfoLink';
import AuthFormCheckbox from '@/components/partials/AuthFormCheckbox';
import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';

export default {
  name: 'LoginPage',
  components: { TheArrowHorizIcon, AuthFormCheckbox, AppLegalInfoLink },
  metaInfo: {
    title: 'Sign in',
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(3),
    },
  },
  data() {
    return {
      email: '',
      password: '',
      keepMeLoggedIn: false,
      formSubmitted: false,
      loginError: '',
      marketingSiteBaseUrl: AppConfig.getAsString('marketingSiteBaseUrl'),
    };
  },
  computed: {
    ...mapGetters(['isUserMembershipLapsed']),
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.login();
    },
    login() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      this.loginError = '';

      this.$store
        .dispatch('login', {
          email: this.email.toLowerCase(),
          password: this.password,
          keepMeLoggedIn: this.keepMeLoggedIn,
        })
        .then(() => {
          this.email = '';
          this.password = '';
          this.keepMeLoggedIn = false;
          this.$v.$reset();

          let redirectTo = this.$route.query.redirect ? this.$route.query.redirect : '/';

          if (this.isUserMembershipLapsed) {
            redirectTo = { name: 'payment-details' };
          } else if (/^(https?:)?\/\//.test(redirectTo)) {
            redirectTo = '/';
          }

          this.$router.replace(redirectTo);
        })
        .catch(({ status, data: err }) => {
          let error = 'Something went wrong. Please try again later or contact with administrator.';
          if (status === 422) {
            this.loginError = 'Invalid credentials.';
            error = `Failed to log in. ${this.loginError}`;
          } else if (err.message) {
            this.loginError = err.message;
            error = 'Failed to log in.';
          }
          this.$toast.error(error);
        })
        .finally(() => {
          this.formSubmitted = false;
        });
    },
  },
};
</script>
